var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.BCXTable,{staticClass:"my-projects-contract-file-table",attrs:{"rows":_setup.fakeRows,"field-names":_setup.fieldNames,"sortable-fields":_setup.fieldNames,"header-fields":_setup.fieldNames,"header-titles":[
    _vm.$t('contracts.contract.contract.details.table.columns.nr')
    , _vm.$t('contracts.contract.contract.details.table.columns.date')
    , _vm.$t('contracts.contract.contract.details.table.columns.from')
  ],"sort-value-callback":_setup.sortCallback,"display-value-callback":_setup.displayCallback,"template-columns":"0.7fr 3fr 7.5fr","mobile-template-columns":"2fr 5.5fr","mobile-grid-areas":'position date\nuploader uploader'},scopedSlots:_vm._u([{key:"table-cell",fn:function({fieldName, displayValue, isMobile }){return [_c('div',{staticClass:"c-cell bcx-truncated-text",class:[`c-${fieldName}`, {
        'c-with-buttons': (fieldName==='uploader' && !isMobile) || (fieldName==='date' && isMobile),
      }]},[_c('span',[_vm._v(" "+_vm._s(displayValue)+" ")]),((fieldName==='uploader' && !isMobile) || (fieldName==='date' && isMobile))?_c(_setup.MyProjectsContractFileTableButtons):_vm._e()],1)]}},{key:"empty",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('contracts.contract.contract.details.table.empty'))+" ")]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }