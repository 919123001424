import useResponsiveness from '@/mixins/useResponsiveness';
import { COUNTABLE_NOTIFICATION_TYPES, Notifications, NotificationType } from '@/models/Notification';
import { clearNotifications, getAllNotifications } from '@/services/NotificationsService';
import useFullPageState from '@/state/fullPageState';
import { computed, ref, watch } from 'vue';
import { createGlobalState, debouncedRef, useDocumentVisibility } from '@vueuse/core';
import useIntervalRefresher, { INTERVAL_RESET, intervalRefresherEventBus } from '@/mixins/useIntervalRefresher';
import {
  REFRESH_INTERVAL_NOTIFICATIONS_BEGINNING,
  REFRESH_INTERVAL_NOTIFICATIONS_ENDING,
  REFRESH_INTERVAL_NOTIFICATIONS_TIMESPAN
} from '@/models/IntervalTimes';
import useMessengerState from '@/state/messenger/messengerState';
import { MESSAGE_EVENT_REFRESH } from '@/models/Messenger';
import { logDebug } from '@/utils/logger';

const useNotificationState = createGlobalState(() => {
  const { isSmartphoneWidth } = useResponsiveness();
  const { isOnFullPage } = useFullPageState();
  const { eventBus: messengerEventBus } = useMessengerState();

  const notifications = ref<Notifications | null>(null);

  const showPanel = ref(false);
  const whichPanel = ref<NotificationType | ''>(NotificationType.Messages);
  const panelPos = ref(0);

  const notificationCounts = computed(() => {
    if (!notifications.value) return null;
    return Object.fromEntries(
      Object.entries(notifications.value)
        .filter(([type]) => COUNTABLE_NOTIFICATION_TYPES.includes(type as NotificationType))
        .map(([type, values]) => [type, Object.values(values).reduce((prev, cur) => prev + cur)])
    );
  });

  const totalCount = computed(() => (notificationCounts.value ? Object.values(notificationCounts.value).reduce((prev, cur) => prev + cur) : 0));

  const totalMessages = computed(() => notificationCounts.value?.[NotificationType.Messages] ?? 0);

  const which = computed(() => (isSmartphoneWidth.value ? whichPanel.value : ''));

  const forceFlexibleWidth = ref(false);

  const loadNotifications = async () => {
    if (!isOnFullPage.value) return false;

    const newNotifications = await getAllNotifications();
    if (newNotifications) {
      notifications.value = newNotifications;
      return true;
    }
    return false;
  };

  const documentVisibility = useDocumentVisibility();
  const debouncedVisibility = debouncedRef(documentVisibility, 1000);

  watch(debouncedVisibility, (v) => {
    if (v === 'visible') {
      logDebug('Refresh Notifications: Tab Activated');
      loadNotifications();
    }
  }, { immediate: true });

  useIntervalRefresher(() => {
    logDebug('Refresh Notifications: Timer');
    loadNotifications();
  }, {
    beginningInterval: REFRESH_INTERVAL_NOTIFICATIONS_BEGINNING,
    endingInterval: REFRESH_INTERVAL_NOTIFICATIONS_ENDING,
    timeSpan: REFRESH_INTERVAL_NOTIFICATIONS_TIMESPAN,
    name: 'Notifications',
  });

  logDebug('Refresh Notification: Initial');
  loadNotifications().then();

  const resetInterval = (reason = '') => {
    intervalRefresherEventBus.emit(INTERVAL_RESET, reason);
  };

  watch(showPanel, (is) => {
    if (is) resetInterval('Show notification panel');
  });

  watch(totalMessages, (total) => {
    logDebug('totalMessages changed:', total, 'doing a meta refresh');
    messengerEventBus.emit(MESSAGE_EVENT_REFRESH);
  });

  const clear = async (w: NotificationType) => {
    await clearNotifications(w);
    resetInterval('Entries deleted');
    showPanel.value = false;
  };

  return ({
    notifications,
    loadNotifications,
    clear,
    showPanel,
    whichPanel,
    which,
    panelPos,
    resetInterval,
    forceFlexibleWidth,
    notificationCounts,
    totalCount,
  });
});

export default useNotificationState;
