var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"bc-my-projects-offers-status-selector bcx-pointer",class:[
    `mp-offer-status-${_vm.status.toLowerCase()}`,
    {
      'c-selected-offer-status': _vm.isSelected,
      'is-narrow': _setup.isNarrow,
      'c-strongcolor': _vm.isSelected && (_vm.status === 'DECLINED' || _vm.status === 'WITHDRAWN')
    }],on:{"click":function($event){return _vm.$emit('click')}}},[_c('span',[_vm._v(_vm._s(_setup.t(`my-projects.offers.filter-values.${_vm.status}`)))]),(!_setup.isNarrow)?_c(_setup.BCXIcon,{staticClass:"c-icon",attrs:{"icon":"Chevron","orientation":_vm.isSelected ? 'up' : 'down',"width":14,"height":14,"stroke-width":1}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }