import {
  computed, ref, Ref, watch
} from 'vue';
import { BcxFormField, BcxFormFieldErrorMessage } from '@/models/BcxForm';
import { watchImmediate } from '@vueuse/core';
import { useFormValidation } from '@/utils/bcxFormValidations';

const useBcxForm = (data: Ref<Record<string, string>>, fields: Ref<BcxFormField[]>) => {
  const errorMessages = ref<Record<string, BcxFormFieldErrorMessage>>({});
  const { validateField } = useFormValidation();
  const hasTouched = ref(false);

  const validateFields = (fieldNames: string[]) => {
    const tmpValidations = { ...errorMessages.value };
    fieldNames.forEach((fieldName) => {
      const field = fields.value.find((field) => field.name === fieldName);
      if (!field) return;
      const message = validateField(data.value[fieldName], field, data.value);
      tmpValidations[fieldName] = {
        message,
        isVisible: (tmpValidations?.[fieldName]?.isVisible) ?? false
      };
    });

    errorMessages.value = tmpValidations;
  };

  // always validate from the beginning. Validation errors will then only be shown on blur (see above)
  watchImmediate([data, fields], ([data, fields], [oldData]) => {
    const fieldNames = Object.entries(data)
      .filter(
        ([name, value]) => {
          const hasField = (fields as BcxFormField[]).some((field) => field.name === name);
          return (oldData === undefined || value !== (oldData as Record<string, string>)?.[name]) && hasField;
        }
      ).map(([key]) => key);
    validateFields(fieldNames);
  });

  watch(fields, (newFields, oldFields) => {
    if (!hasTouched.value) return;

    const fieldNames = newFields.filter((field, idx) => {
      const oldField = oldFields.at(idx);
      return oldField?.triggerValidation !== field?.triggerValidation;
    }).map((field) => field.name);
    validateFields(fieldNames);
  });

  const onBlur = (field: BcxFormField) => {
    errorMessages.value[field.name].isVisible = true;
    hasTouched.value = true;
  };

  const revealAllErrors = () => {
    const tmpErrorMessages = { ...errorMessages.value };
    fields.value.forEach((field) => {
      const tmpErrorMessage = tmpErrorMessages[field.name];
      if (tmpErrorMessage) tmpErrorMessage.isVisible = true;
    });
    errorMessages.value = tmpErrorMessages;
  };

  const validateAllFields = () => {
    const fieldNames = fields.value.map((field) => field.name);
    validateFields(fieldNames);
    revealAllErrors();
  };

  const hasErrors = computed(() => Object.values(errorMessages.value).some((error) => error.message && error.isVisible));

  return {
    onBlur,
    hasTouched,
    errorMessages,
    hasErrors,
    validateAllFields,
    revealAllErrors
  };
};

export default useBcxForm;
