import { SelectItem } from '@/models/SelectItem';

export interface BcxFormFieldTextareaOptions {
  height?: number;
  enlargedHeight?: number;
  maxLength?: number;
}

export interface BcxFormFieldDateOptions {
  earliest?: string | Date;
  latest?: string | Date;
  secondary?: boolean;
}

export enum BcxFormValidation {
  EMAIL = 'email',
  ZIP = 'zip'
}

export type BcxFormCustomValidation = (value: string, data: Record<string, string>) => string | null;

export interface BcxFormFieldErrorMessage {
  message: string;
  isVisible: boolean;
}

export interface BcxFormField {
  name: string;
  type: 'text' | 'textarea' | 'select' | 'checkbox' | 'date';
  label: string;
  width?: string;
  fieldWidth?: string;
  placeholder?: string;
  required?: boolean;
  validations?: Array<BcxFormValidation | BcxFormCustomValidation>;
  disabled?: boolean;
  suffix?: string;
  options?: SelectItem[];
  textareaOptions?: BcxFormFieldTextareaOptions;
  dateOptions?: BcxFormFieldDateOptions;
  triggerValidation?: boolean; // used in reactive field structures to trigger re-validation of field (for example, when combined with other fields)
}
