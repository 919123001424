
import BCXCheckbox from '@/components/molecules/forms/BCXCheckbox.vue';
import { useBarOpeningHours } from '@/views/virtual-pub/composable/useBarOpeningHours';
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vue2-helpers/vuex';
import useI18n from '../../../../mixins/useI18n';
import { onlyNewContacts } from '../../composable/bar-chat/useEnterBarChat';
import useChatRoomRouter from '../../composable/useChatRoomRouter';
import VirtualPubSectionPanel from '../molecules/VirtualPubSectionPanel.vue';

export default defineComponent({
  components: {
    VirtualPubSectionPanel,
    BCXCheckbox
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const { routeToBarChat } = useChatRoomRouter();
    const { isBarClosingSoon, canJoinRoom } = useBarOpeningHours();

    const userIsAdmin = computed(() => store.getters.user.roles.includes('ADMIN'));
    const onlyNewPeople = ref(false);

    async function joinBarChat(newContacts = false) {
      onlyNewContacts.value = newContacts;
      await routeToBarChat();
    }

    const barChatSubtitle = computed(() => {
      if (!canJoinRoom.value && isBarClosingSoon.value) {
        return t('video_chat.bar_chat.cannot_join_bar.pub_closing_soon').toString();
      }
      return t('video_chat.bar_chat.sub_title').toString();
    });

    const canChat = computed(() => canJoinRoom.value);

    return {
      canChat,
      t,
      joinBarChat,
      onlyNewPeople,
      userIsAdmin,
      barChatSubtitle,
    };
  }
});
