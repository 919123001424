
import {
  computed,
  ComputedRef,
  defineComponent,
  PropType,
  toRef,
  toRefs
} from 'vue';
import {
  onClickOutside, templateRef, useElementBounding, useVModel, useWindowSize
} from '@vueuse/core';
import Chevron from '@/components/svg-components/Chevron.vue';

const MAX_PANEL_WIDTH = 250;

export default defineComponent({
  name: 'BCXButtonDropdown',
  components: {
    Chevron
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    isHighlighted: Boolean,
    hasError: Boolean,
    isOpen: Boolean,
    maxPanelWidth: {
      type: Number,
      default: MAX_PANEL_WIDTH
    },
    mode: {
      type: String as PropType<'filter' | 'dropdown'>,
      default: 'filter'
    }
  },
  setup(props) {
    const { mode } = toRefs(props);
    const componentRef = templateRef<HTMLElement>('componentRef');
    const baseRef = templateRef<HTMLElement>('baseRef');
    const showDropdown = useVModel(props, 'isOpen');
    const maxPanelWidth = toRef(props, 'maxPanelWidth');

    const chevronOrientation: ComputedRef<'up' | 'down'> = computed(() => (showDropdown.value ? 'up' : 'down'));

    const setShowDropdown = (value: boolean) => {
      showDropdown.value = value;
    };

    onClickOutside(componentRef, () => {
      setShowDropdown(false);
    });

    const { left: baseLeft, bottom: baseBottom, top: baseTop } = useElementBounding(baseRef);
    const { width: windowWidth, height: windowHeight } = useWindowSize();

    const isAnchorRight = computed(() => baseLeft.value + maxPanelWidth.value >= windowWidth.value);
    const isAnchorBottom = computed(() => (baseBottom.value + baseTop.value) * 0.5 >= windowHeight.value * 0.5);

    const isModeFilter = computed(() => mode.value === 'filter');
    const isModeDropdown = computed(() => mode.value === 'dropdown');

    return {
      chevronOrientation,
      setShowDropdown,
      showDropdown,
      isAnchorRight,
      isAnchorBottom,
      isModeFilter,
      isModeDropdown,
    };
  }
});
